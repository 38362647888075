
import {
    defineComponent,
    onMounted,
    ref,
    reactive,
    nextTick,
    h,
    computed,
    watch,
} from 'vue';
import moment from 'moment';
import {
    applicableTypeClassList,
    queryRestrictCityByBu,
    getAllProvince,
    getCityListByProvinceId,
    saveRegRestrictCity,
    deleteRegRestrictCity,
    getTypclassByBu,
} from '@/API/localRegistration';
import Title from '../components/title.vue';
export default defineComponent({
    components: {
        Title: Title,
    },
    setup() {
        const bu = ref<string>('');
        const buList = ref<any[]>([]);
        const typeClassList = ref<string[]>([]);
        const typeClass = ref<any>('');
        const isEditing = ref<boolean>(false);
        const provinceList = ref<any[]>([]);
        const cityList = ref<any[]>([]);

        const handleEdit = () => {
            isEditing.value = true;
        };
        const columns = [
            {
                title: 'Province',
                dataIndex: 'province',
                key: 'province',
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: 'Restriction From',
                dataIndex: 'restrictionFrom',
                key: 'restrictionFrom',
            },
            {
                title: 'To',
                dataIndex: 'restrictionTo',
                key: 'restrictionTo',
            },
        ];
        const handlerColumns = [
            {
                title: 'Province',
                dataIndex: 'province',
                key: 'province',
                slots: { customRender: 'province' },
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                slots: { customRender: 'city' },
            },
            {
                title: 'Restriction From',
                dataIndex: 'restrictionFrom',
                key: 'restrictionFrom',
                slots: { customRender: 'restrictionFrom' },
            },
            {
                title: 'To',
                dataIndex: 'restrictionTo',
                key: 'restrictionTo',
                slots: { customRender: 'restrictionTo' },
            },
            {
                title: 'Operation',
                dataIndex: 'operation',
                key: 'operation',
                slots: { customRender: 'operation' },
            },
        ];

        const data = ref<any[]>([]);
        const handlerData = ref<any[]>([]);

        const handleRemove = (record: any, index: number) => {
            if (record.id) {
                const params = {
                    id: record.id,
                };
                deleteRegRestrictCity(params).then(() => {
                    handlerData.value.splice(index, 1);
                });
            } else {
                handlerData.value.splice(index, 1);
            }
        };

        const initData = () => {
            queryRestrictCityByBu({ bu: bu.value }).then((res: any) => {
                data.value = res.data;
                handlerData.value = res.data;
                getAllProvince().then((res: any) => {
                    provinceList.value = res;
                    handlerData.value.forEach((row) => {
                        if (row.province && !row.cityList) {
                            const province = provinceList.value.filter(
                                (item) => item.name === row.province
                            )[0];
                            const id = province.id;
                            getCityListByProvinceId({ provinceId: id }).then(
                                (res: any) => {
                                    row.cityList = res.data;
                                }
                            );
                        }
                    });
                });
            });
        };

        const handlerBuChange = (value: any) => {
            initData();
        };

        const handleSave = (record: any) => {
            console.log(record);
            const data = {
                bu: bu.value,
                province: record.province,
                city: record.city,
                restrictionFrom:
                    moment(record.restrictionFrom).format('YYYY-MM-DD') ===
                    'Invalid date'
                        ? null
                        : moment(record.restrictionFrom).format('YYYY-MM-DD'),
                restrictionTo:
                    moment(record.restrictionTo).format('YYYY-MM-DD') ===
                    'Invalid date'
                        ? null
                        : moment(record.restrictionTo).format('YYYY-MM-DD'),
                status: 0,
                id: record.id,
                createdAt: record.createdAt || null,
                createdBy: record.createdBy || null,
                updatedAt: record.updatedAt || null,
                updatedBy: record.updatedBy || null,
            };
            saveRegRestrictCity(data).then((res: any) => {
                if (!record.id) {
                    record.id = res.id;
                    record.createdAt = res.createdAt;
                    record.createdBy = res.createdBy;
                    record.updatedAt = res.updatedAt;
                    record.updatedBy = res.updatedBy;
                }
            });
        };

        const handlerProvinceChange = (value: any, record: any) => {
            const province = provinceList.value.filter(
                (item) => item.name === value
            )[0];
            const id = province.id;
            const params = {
                provinceId: id,
            };
            record.city = '';
            getCityListByProvinceId(params).then((res: any) => {
                record.cityList = res.data;
            });
            handleSave(record);
        };

        const handleAdd = () => {
            handlerData.value.push({
                province: '',
                city: '',
                restrictionFrom: null,
                restrictionTo: null,
                status: 0,
            });
        };

        const handleClose = () => {
            initData();
            isEditing.value = false;
        };

        const filterOption = (input: any, option: any) => {
            return option.children[0].children.indexOf(input) >= 0;
        };

        onMounted(async () => {
            // applicableTypeClassList().then((res: any) => {
            //   typeClassList.value = res
            //   typeClass.value = res[0]
            //   initData()
            // })
            getTypclassByBu().then((res: any) => {
                buList.value = [];
                for (const key in res) {
                    const item = {
                        label: key,
                        value: key,
                        typeClassList: res[key],
                    };
                    if (res[key]?.length > 0) {
                        buList.value.push(item);
                    }
                }
                bu.value = buList.value[0].value;
                initData();
            });
        });

        return {
            bu,
            buList,
            filterOption,
            typeClassList,
            provinceList,
            cityList,
            typeClass,
            columns,
            handlerColumns,
            data,
            handlerData,
            handleRemove,
            isEditing,
            handleEdit,
            handlerProvinceChange,
            handleAdd,
            handleSave,
            handleClose,
            handlerBuChange,
        };
    },
});
